import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'

const Index = props => {
  const {
    data: {
      allSitePage: { nodes: pages },
    },
  } = props

  return (
    <Layout>
      <section className="section">
        <h1>All Collections:</h1>
        <ul>
          {pages
            .filter(({ path }) => !(path.match(/404/) || path.length === 1))
            .map(({ path }) => (
              <li>
                <Link to={path}>{path}</Link>
              </li>
            ))}
        </ul>
      </section>
    </Layout>
  )
}

export default Index

export const query = graphql`
  {
    allSitePage {
      nodes {
        path
      }
    }
  }
`
